.bg-lightgreen {
    background-color: #c9fc96 ;
}
.bg-grey{
    background-color: rgba(211, 211, 211, 0.591);
}
.bg-lightred{
    background-color: #faa597;
}
.bg-lightyellow{
    background-color: rgb(254, 254, 164);
}