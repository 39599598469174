
.main-flatCard {
	background-color: rgba(180, 180, 180, 0.025);
	
	transition: box-shadow 0.3s, border-color 0.3s, -webkit-box-shadow 0.3s;
	// box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
    box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 2px 6px 2px rgb(60 64 67 / 15%);
	
	// border: 2px solid rgba(0,0,0,0.1);
	border-radius: 6px;
}
.main-flatCard:hover {
	// border: 2px solid rgba(0,0,0,0.25);
	transition: box-shadow 0.3s, border-color 0.3s, -webkit-box-shadow 0.3s;
	// box-shadow: 0 2px 3px -2px rgba(0, 0, 0, 0.16), 0 6px 9px 0 rgba(0, 0, 0, 0.12), 0 10px 24px 8px rgba(0, 0, 0, 0.09);
	box-shadow: 0 2px 4px 0 rgb(60 64 67 / 30%), 0 4px 12px 4px rgb(60 64 67 / 15%);
}