.full-width {
  width: 100%;
}

.right {
  float: right;
}

.space-right {
  margin-right: 5px;
}

.space-left {
  margin-left: 15px;
}

.flex-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.flex-spaced {
  margin-top: 15px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.grid-list {
  display: grid;
  grid-template-columns: 90% 10%;
  padding: 5px;
}

.flex-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 80vw;
}

.flex-list-item {
  flex: 1 1;
}

.shortener {
  display: inline-block;
  float: left;
  margin-right: 20px;
  width: 500px;
}

.grid-view {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.grid-list:hover {
  border: 1px solid #d3d3d3;
}

.title {
  font-size: 20px;
  font-weight: bold;
  color: black;
}

.subtitle {
  font-size: 15px;
  font-weight: 500;
}
.actions {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.row {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
}

.panel-body {
  width: 70vw;
}

.download-grid {
  display: grid;
  grid-template-columns: 0fr;
}

.grid-checkbox {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.grid-inputs {
  display: grid;
  grid-template-columns: 4fr 1fr 0.5fr;
}

.table-analytics {
  border-collapse: collapse;
  width: 100%;
  max-width: 550px;
  font-size: 1em;
  border: 1px solid lightgray;
}

.row-content-analytics {
  text-align: left;
  padding: 8px;
  border: 1px solid lightgray;
}

.analytics-header {
  background-color: #f2f2f2;
  font-weight: bold;
  color: #555;
}

.row-caracteristica {
  font-weight: bolder;
}

.table-analytics-header {
  width: 100%;
  font-weight: bold;
  margin-left: 5px;
}

.question-heading {
  font-weight: 800;
  font-size: 20px;
}

.box {
  border: 1px solid #a6a6a6;
  border-radius: 25px;
  padding: 15px;
  margin-top: 10px;
  color: black;
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 140px;
  height: fit-content;
}

.box-question {
  border: 1px solid #a6a6a6;
  border-radius: 25px;
  padding: 15px;
  margin-top: 10px;
  color: black;
  display: flex;
  flex-direction: column;
  height: fit-content;
}

.box-icon {
  font-size: 32px;
}

.panel-heading {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.box-title {
  font-size: 15px;
  padding: 5px;
}

.box-list {
  display: flex;
  gap: 14px;
  max-width: inherit;
  flex-wrap: wrap;
}

.info-box {
  cursor: pointer;
}

.info-box:hover {
  border: 1px solid black;
}

.chart {
  max-height: 400px;
}

.clickable-icon {
  cursor: pointer;
}
