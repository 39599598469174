
// https://medium.com/codeartisan/breakpoints-and-media-queries-in-scss-46e8f551e2f2



// Small devices
@mixin mobile {
	@media (max-width: 576px) {
		@content;
	}
}

// Medium devices
@mixin tablet {
	@media (max-width: 768px) {
		@content;
	}
}

// Large devices
@mixin miniDesktop {
	@media (max-width: 1199px) {
		@content;
	}
}

// Large devices
@mixin desktop {
	@media (max-width: 1200px) {
		@content;
	}
}

// Custom devices
@mixin lte($px) {
	@media (max-width: ($px - 1) + "px") {
		@content;
	}
}
@mixin gte($px) {
	@media (min-width: $px + "px") {
		@content;
	}
}	