
.main-globalLayout {
	
	background: transparent !important;
	
	.ant-layout {
		background: transparent !important;
	}
	
	
	.navElementContainer {
		background: transparent;
		color: var(--colGriCla);
	}
	.navElementContainer:hover {
		color: white !important;
	}
	
	.navElementContainerActive {
		background: var(--colVerCor) !important;
		color: white !important;
	}
	
}
