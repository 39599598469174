
.main-cargando {
	
	.cajaCargando {
		position: sticky;
		top: 50vh;
		left: 50vw;
		z-index: 9;
	}
	
	.cargando {
		transition: all 0.3s ease-in-out;
		filter: blur(30px);
	}
	
}
