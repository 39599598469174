@import "./mixins.scss";


$colors: (
	colBla: rgb(255, 255, 255),
	colBlaGri: #f0f2f5,
	colNeg: rgb(65, 65, 65),
	colGri: rgb(136, 136, 136),
	colGriCla: rgb(185, 185, 185),
	colGriTra35: rgba(139, 139, 139, 0.35),
	colGriTra15: rgba(139, 139, 139, 0.15),
	colVer: rgb(13, 167, 41),
	colVerCla: rgb(70, 228, 99),
	colVerOsc: rgb(22, 121, 40),
	colRoj: rgba(235, 0, 43, 0.836),
	colRojCla: rgb(255, 55, 55),
	colAzu: rgb(30, 109, 255),
	colAzuCla: rgb(30, 165, 255),
	colAzuMuyCla: rgb(117, 200, 255),
	colAma: rgb(238, 234, 10),
	colNarCla: rgb(255, 197, 8),
	colNar: #F9AA33,

	colVerCor: rgb(0, 207, 167),
	// HEX: #00cfa7
	colVerCorTra: rgba(0, 207, 166, 0.05),
	colVerOscCor: rgb(0, 177, 141),
	colVerClaCor: rgb(29, 231, 191),
	colAzuCor: rgb(105, 164, 255),
	colAzuClaCor: rgb(142, 187, 255),
	colAzuOscCor: rgb(64, 123, 255),

);



:root {

	// Variables CSS generadas automáticamente a partir del map $colors
	@each $name,
	$color in $colors {
		// --colRoj: "red";
		--#{$name}: #{$color};
	}


	// Color que cambia al estar en luz o gas (se modifica desde JS)
	--colLuzGas: var(--colVerCor);
	--hueRotate: var(0deg); // en gas 65deg

}

.ta01 {
	transition: all 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ta02 {
	transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ta03 {
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ta04 {
	transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ta05 {
	transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
}


.fo0 {
	filter: opacity(0);
}

.fo1 {
	filter: opacity(0.1);
}

.fo2 {
	filter: opacity(0.2);
}

.fo3 {
	filter: opacity(0.3);
}

.fo4 {
	filter: opacity(0.4);
}

.fo5 {
	filter: opacity(0.5);
}

.fo6 {
	filter: opacity(0.6);
}

.fo7 {
	filter: opacity(0.7);
}

.fo8 {
	filter: opacity(0.8);
}

.fo9 {
	filter: opacity(0.9);
}

.fo10 {
	filter: opacity(1);
}



// ***********************************************************
// Clases colores
// ***********************************************************

// Clases generadas automáticamente con el mismo nombre que el nombre del color
@each $colorName,
$color in $colors {
	.#{$colorName} {
		color: $color !important;
	}

	.#{$colorName}-ni {
		color: $color;
	}

	.#{$colorName}-background {
		background-color: $color;
	}
}

.colorLuzGas {
	color: var(--colLuzGas) !important
}

.hueRotate {
	filter: hue-rotate(var(--hueRotate))
}

;



// ***********************************************************
// Fuentes
// ***********************************************************

@font-face {
	font-family: "gana";
	src: url("./fonts/ggregular.otf");
}

@font-face {
	font-family: "ganaBold";
	src: url("./fonts/ggmedium.otf");
}

@font-face {
	font-family: "ganaBolder";
	src: url("./fonts/ggbold.otf");
}

@import url('https://fonts.cdnfonts.com/css/montserrat');


// ***********************************************************
// Reset
// ***********************************************************

* {
	margin: 0;
	padding: 0;

	// color: var(--pri9);
	font-family: "gana", 'DM Sans', sans-serif;
	// font-size: 17px;
	// font-size: 103%;


	@include tablet {
		font-size: 0.98em;
	}

	@include mobile {
		font-size: 0.95em;
	}

}

html {
	scroll-behavior: smooth;


	/* width */
	::-webkit-scrollbar {
		width: 8px;
		height: 8px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		background: #f1f1f1;
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: #888;
	}

	::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
}


body {
	font-size: 16px;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='1920' height='1080' preserveAspectRatio='none' viewBox='0 0 1920 1080'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1096%26quot%3b)' fill='none'%3e%3cpath d='M -1674.2531739313622%2c90 C -1482.25%2c263.8 -1098.25%2c939.6 -714.2531739313623%2c959 C -330.25%2c978.4 -138.25%2c173.2 245.7468260686377%2c187 C 629.75%2c200.8 870.9%2c984 1205.7468260686378%2c1028 C 1540.6%2c1072 1777.15%2c531.2 1920%2c407' stroke='rgba(0%2c 207%2c 167%2c 0.1)' stroke-width='2'%3e%3c/path%3e%3cpath d='M -1736.6065116616628%2c780 C -1544.61%2c674.2 -1160.61%2c224.4 -776.6065116616627%2c251 C -392.61%2c277.6 -200.61%2c953.6 183.3934883383373%2c913 C 567.39%2c872.4 796.07%2c88 1143.3934883383372%2c48 C 1490.71%2c8 1764.68%2c580 1920%2c713' stroke='rgba(0%2c 207%2c 167%2c 0.1)' stroke-width='2'%3e%3c/path%3e%3cpath d='M -1394.8309704093294%2c965 C -1202.83%2c792.8 -818.83%2c158 -434.83097040932955%2c104 C -50.83%2c50 141.17%2c623 525.1690295906704%2c695 C 909.17%2c767 1206.2%2c457.8 1485.1690295906706%2c464 C 1764.14%2c470.2 1833.03%2c673.6 1920%2c726' stroke='rgba(0%2c 207%2c 167%2c 0.1)' stroke-width='2'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1096'%3e%3crect width='1920' height='1080' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e");
}



// ***********************************************************
// Estados
// ***********************************************************

.cp {
	cursor: pointer;
}

.hide {
	visibility: hidden;
	display: none;
}

.hideOnTablet {
	@include tablet {
		display: none !important;
	}
}

.hideOnMobile {
	@include mobile {
		display: none !important;
	}
}

.hideOnDesktop {
	@include desktop {
		display: none !important;
	}
}

.shadow {
	transition: box-shadow 0.3s, border-color 0.3s, -webkit-box-shadow 0.3s;
	box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
}

.shadowHover:hover {
	transition: box-shadow 0.3s, border-color 0.3s, -webkit-box-shadow 0.3s;
	box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
}

.elipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.ofys {
	overflow-y: scroll;
}

.ofxs {
	overflow-x: scroll;
}

.hover:hover {
	filter: brightness(1.1);
}

.hoverDark:hover {
	filter: brightness(0.85);
}

.hoverDarkBackground:hover {
	background: rgba(0, 0, 0, 0.04);
}


// ***********************************************************
// Positions
// ***********************************************************

.abs {
	position: absolute;
}

.rel {
	position: relative;
}

.verCambios {
	display: flex;
	position: fixed;
	justify-content: flex-end;
}


// ***********************************************************
// Flex
// ***********************************************************

.flex {
	display: flex !important
}

;

.fdr {
	display: flex;
	flex-direction: row
}

;

.fdc {
	display: flex;
	flex-direction: column
}

;

.jcc {
	display: flex;
	justify-content: center
}

;

.jcfs {
	display: flex;
	justify-content: flex-start
}

;

.jcfe {
	display: flex;
	justify-content: flex-end
}

;

.jcsa {
	display: flex;
	justify-content: space-around
}

;

.jcsb {
	display: flex;
	justify-content: space-between
}

;

.jcse {
	display: flex;
	justify-content: space-evenly
}

;

.aic {
	display: flex;
	align-items: center
}

;

.aifs {
	display: flex;
	align-items: flex-start
}

;

.aife {
	display: flex;
	align-items: flex-end
}

;

.fww {
	display: flex;
	flex-wrap: wrap
}

;

.fg1 {
	flex-grow: 1
}

;

.flex01 {
	flex: 0.1
}

;

.flex02 {
	flex: 0.2
}

;

.flex03 {
	flex: 0.3
}

;

.flex04 {
	flex: 0.4
}

;

.flex05 {
	flex: 0.5
}

;

.flex06 {
	flex: 0.6
}

;

.flex07 {
	flex: 0.7
}

;

.flex08 {
	flex: 0.8
}

;

.flex09 {
	flex: 0.9
}

;

.flex1 {
	flex: 1
}

;

.flex11 {
	flex: 1.1
}

;

.flex12 {
	flex: 1.2
}

;

.flex13 {
	flex: 1.3
}

;

.flex14 {
	flex: 1.4
}

;

.flex15 {
	flex: 1.5
}

;

.flex16 {
	flex: 1.6
}

;

.flex17 {
	flex: 1.7
}

;

.flex18 {
	flex: 1.8
}

;

.flex19 {
	flex: 1.9
}

;

.flex2 {
	flex: 2
}

;



// ***********************************************************
// Padding
// ***********************************************************

$em0: 0em;
$em1: 0.25em;
$em2: 0.5em;
$em3: 1em;
$em4: 1.5em;
$em5: 2em;

.p0 {
	padding: $em0 !important;
}

.pt0 {
	padding-top: $em0 !important;
}

.pr0 {
	padding-right: $em0 !important;
}

.pb0 {
	padding-bottom: $em0 !important;
}

.pl0 {
	padding-left: $em0 !important;
}

.p1 {
	padding: $em1 !important;
}

.pt1 {
	padding-top: $em1 !important;
}

.pr1 {
	padding-right: $em1 !important;
}

.pb1 {
	padding-bottom: $em1 !important;
}

.pl1 {
	padding-left: $em1 !important;
}

.p2 {
	padding: $em2 !important;
}

.pr2 {
	padding-right: $em2 !important;
}

.pt2 {
	padding-top: $em2 !important;
}

.pb2 {
	padding-bottom: $em2 !important;
}

.pl2 {
	padding-left: $em2 !important;
}

.p3 {
	padding: $em3 !important;
}

.pt3 {
	padding-top: $em3 !important;
}

.pr3 {
	padding-right: $em3 !important;
}

.pb3 {
	padding-bottom: $em3 !important;
}

.pl3 {
	padding-left: $em3 !important;
}

.p4 {
	padding: $em4 !important;
}

.pr4 {
	padding-right: $em4 !important;
}

.pt4 {
	padding-top: $em4 !important;
}

.pb4 {
	padding-bottom: $em4 !important;
}

.pl4 {
	padding-left: $em4 !important;
}

.p5 {
	padding: $em5 !important;
}

.pt5 {
	padding-top: $em5 !important;
}

.pr5 {
	padding-right: $em5 !important;
}

.pb5 {
	padding-bottom: $em5 !important;
}

.pl5 {
	padding-left: $em5 !important;
}

.responsiveWidth {

	margin: auto;

	@include gte(1200) {
		max-width: 1140px
	}

	@include lte(1199) {
		max-width: 960px
	}

	@include lte(992) {
		max-width: 720px
	}

	@include lte(768) {
		max-width: 600px
	}

	@include lte(645) {
		max-width: 540px
	}

	@include lte(585) {
		padding: 0 1em
	}


}


// ***********************************************************
// Border-Radius
// ***********************************************************

.bRad0 {
	border-radius: 1;
}

.bRad1 {
	border-radius: 2;
}

.bRad2 {
	border-radius: 3;
}

.bRad3 {
	border-radius: 4;
}

.bRad4 {
	border-radius: 5;
}


.bRad1px {
	border-radius: 1px;
}

.bRad2px {
	border-radius: 2px;
}

.bRad3px {
	border-radius: 3px;
}

.bRad4px {
	border-radius: 4px;
}

.bRad5px {
	border-radius: 5px;
}

.bRad6px {
	border-radius: 6px;
}

.bRad7px {
	border-radius: 7px;
}

.bRad8px {
	border-radius: 8px;
}

.bRad9px {
	border-radius: 9px;
}

// ***********************************************************
// Margin
// ***********************************************************

.m0 {
	margin: $em0 !important;
}

.mt0 {
	margin-top: $em0 !important;
}

.mr0 {
	margin-right: $em0 !important;
}

.mb0 {
	margin-bottom: $em0 !important;
}

.ml0 {
	margin-left: $em0 !important;
}

.m1 {
	margin: $em1 !important;
}

.mt1 {
	margin-top: $em1 !important;
}

.mr1 {
	margin-right: $em1 !important;
}

.mb1 {
	margin-bottom: $em1 !important;
}

.ml1 {
	margin-left: $em1 !important;
}

.m2 {
	margin: $em2 !important;
}

.mt2 {
	margin-top: $em2 !important;
}

.mr2 {
	margin-right: $em2 !important;
}

.mb2 {
	margin-bottom: $em2 !important;
}

.ml2 {
	margin-left: $em2 !important;
}

.m3 {
	margin: $em3 !important;
}

.mt3 {
	margin-top: $em3 !important;
}

.mr3 {
	margin-right: $em3 !important;
}

.mb3 {
	margin-bottom: $em3 !important;
}

.ml3 {
	margin-left: $em3 !important;
}

.m4 {
	margin: $em4 !important;
}

.mt4 {
	margin-top: $em4 !important;
}

.mr4 {
	margin-right: $em4 !important;
}

.mb4 {
	margin-bottom: $em4 !important;
}

.ml4 {
	margin-left: $em4 !important;
}

.m5 {
	margin: $em5 !important;
}

.mt5 {
	margin-top: $em5 !important;
}

.mr5 {
	margin-right: $em5 !important;
}

.mb5 {
	margin-bottom: $em5 !important;
}

.ml5 {
	margin-left: $em5 !important;
}

.mt20 {
	margin-top: 1.1em !important;
}
.mr20 {
	margin-right: 16em !important;
}


// ***********************************************************
// Sizes
// ***********************************************************

// Esto genera w1, w2... w25... w50... w75... hasta w100
@for $i from 1 through 100 {
	.w#{$i} {
		width: #{$i}#{'%'} !important
	}

	;

	.h#{$i} {
		height: #{$i}#{'%'} !important
	}

	;
}


.wfull {
	width: 100vw !important
}

;

.hfull {
	height: 100vh !important
}

;

.full {
	width: 100vw !important;
	height: 100vh !important;
}

.wminc {
	width: min-content !important
}

;

.wmaxc {
	width: max-content !important
}

;

.wfitc {
	width: fit-content !important
}

;

.hminc {
	height: min-content !important
}

;

.hmaxc {
	height: max-content !important
}

;

.hfitc {
	height: fit-content !important
}

;



// Esto genera w1em, w2em... hasta w40em
@for $i from 1 through 10000 {
	.w#{$i}em {
		width: #{$i}em
	}

	;

	.minw#{$i}em {
		min-width: #{$i}em
	}

	;

	.maxw#{$i}em {
		max-width: #{$i}em
	}

	;

	.h#{$i}em {
		height: #{$i}em
	}

	;

	.minh#{$i}em {
		min-height: #{$i}em
	}

	;

	.maxh#{$i}em {
		max-height: #{$i}em
	}

	;
}



// ***********************************************************
// Text
// ***********************************************************

.tac {
	text-align: center !important
}

.tal {
	text-align: left !important
}

.tar {
	text-align: right !important
}

.wsn {
	white-space: normal !important
}

.wspw {
	white-space: pre-wrap !important
}

.wspl {
	white-space: pre-line !important
}

.wsnw {
	white-space: nowrap !important
}

strong {
	font-family: ganaBold !important
}

.bold {
	font-family: ganaBold !important
}

.bolder {
	font-family: ganaBolder !important
}

.colGraRoj {
	background: #CF0000;
	background: linear-gradient(to right, #CF0000 0%, #CFBE27 100%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.colGraVer {
	background: #1E9C61;
	background: linear-gradient(to right, #1E9C61 0%, #88CF0E 100%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.colGraAzu {
	background: #0C9BCF;
	background: linear-gradient(to right, #0C9BCF 0%, #76C3CF 100%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}


// ***********************************************************
// Text size
// ***********************************************************

$fs1: 0.70em;
$fs2: calc(#{$fs1} * 1.10);
$fs3: calc(#{$fs1} * 1.30);
$fs4: calc(#{$fs1} * 1.60);
$fs5: calc(#{$fs1} * 2.00);
$fs6: calc(#{$fs1} * 2.50);
$fs7: calc(#{$fs1} * 3);

.fs1 {
	font-size: $fs1 !important
}

.fs2 {
	font-size: $fs2 !important
}

.fs3 {
	font-size: $fs3 !important
}

.fs4 {
	font-size: $fs4 !important
}

.fs5 {
	font-size: $fs5 !important
}

.fs6 {
	font-size: $fs6 !important
}

.fs7 {
	font-size: $fs7 !important
}



// ***********************************************************
// Animaciones
// ***********************************************************

.transition1 {
	transition: all 0.1s
}

;

.transition2 {
	transition: all 0.2s
}

;

.transition3 {
	transition: all 0.3s
}

;

.transition4 {
	transition: all 0.4s
}

;

.transition5 {
	transition: all 0.5s
}

;



// ***********************************************************
// Antd override
// ***********************************************************

.ant-select-item-option-content {
	white-space: normal !important;
}

.ant-typography-copy-success {
	color: var(--colVerCor) !important;
}

.ant-select-selection-item {
	white-space: pre-line !important;
}

.ant-btn-primary {
	// background: var(--colLuzGas) !important;
	// border-color: var(--colLuzGas) !important;
}

;

.ant-btn-primary:hover:enabled {
	// filter: brightness(107%) !important;
}

.ant-btn-primary:disabled {
	background: #f5f5f5 !important;
	border-color: #d9d9d9 !important;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header {
	// padding: 0 !important;
	display: flex;
	align-items: center;
}

.ant-transfer-operation>button {
	display: flex !important;
	justify-content: center;
	align-items: center;
}

.ant-btn-text[disabled],
.ant-btn-text[disabled]:hover,
.ant-btn-text[disabled]:focus,
.ant-btn-text[disabled]:active {
	opacity: 0.5;
	filter: grayscale(0.2);
	color: unset;
}

.ant-btn-text[disabled] {
	padding: 0;
}

.ant-popover-message-title {
	padding-left: 0 !important;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header {
	padding: 0 !important;
}

.ant-collapse-content>.ant-collapse-content-box {
	// padding: 5px 16px 16px 16px !important;
	padding: 0 !important;
}

.ant-input[disabled] {
	color: rgba(0, 0, 0, 0.55) !important;
}

;



// ***********************************************************
// Elementos base
// ***********************************************************

h1,
h2,
h3,
h4,
h5,
p {
	margin: 0 !important;
	padding: 0 !important;
}

// a,p {
// 	font-size: $fs2;
// }

a {
	// color: var(--colVerOscCor) !important;
	transition: color 0.2s
}

a:hover {
	// color: var(--colVerClaCor) !important;
	filter: brightness(1.15);
}

input {
	font-size: 1.15em !important;
}



// ***********************************************************
// Específicos de gana
// ***********************************************************

.notification-button {
	background-color: #f2f4f5 !important;
	border: none !important;
	color: black !important;
	transition: all 1s !important;
}

.notification-button:hover {
	background-color: #D8D8D8 !important;
	color: black !important;
	border: none !important;
}

.notification-button:focus {
	background-color: #D8D8D8 !important;
	color: black !important;
	border: none !important;
}

.linkHamburguesa {

	@include mobile {
		font-size: 1.5em !important;
	}

	@include tablet {
		font-size: 1.3em;
	}

	@include miniDesktop {
		font-size: 1.1em;
	}

	font-size: 1em;

}



// ***********************************************************
// Pruebas
// ***********************************************************
.scroll-out {
	overflow-y: hidden;
}

.scroll {
	overflow-y: scroll;
}

.pl70 {
	padding-left: 70px;
}