.bloque {
    background-Color: rgba(43, 226, 162, 0.1);
    align-Items: center; 
    padding : 10px
}

.bloque input{
    /* margin-Left: 20px; */
    border-color: rgba(0, 0, 0, 0.1);
}

.linea{
    /* text-Align: right; */
    margin: 10px;
    min-height: 30px; /* Altura minima de la linea */
    height: auto;
    overflow: hidden;
}

.linea Button{
    margin-left: 10px;
    text-align: right;
}

.linea .select{
    width: 100%;
}

.selector{
    width: 100%;
    min-height: 30px; /* Altura minima de la linea */
    /* margin-Left: 10px; */
}

.fullWidth {
    width : 100%;
}

.selector option{
    min-height: 30px; /* Altura minima de la linea */
}

.datePicker{
    margin-Left: 20px;
    width: 61% !important;
}
.rangePicker{
    margin-Left: 20px;
    width: 96% !important;
}

h3 .negrita{
   font-weight: bold;
}

h3 {
    font-size:x-large;
}

.totales {
    background-color: rgba(43, 226, 162, 0.1); 
    padding: 10px;
    align-items: center;
    align-content:space-between;
    text-align: right;
}

.totales p{
    text-align: right;
    font-size:large;
}

.negativo {
    color:rgba(255, 0, 0,0.9) 
}

.notAlign {
    text-align: center !important;
}

.right {
    text-align: right;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  height: auto;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  white-space: normal;
  word-break: break-all;
}


h3 {
    font-size:20px;
}

p {
    font-size:18px;
}