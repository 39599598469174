
.main-card {
	
	
	.card {
		transition: all 0.3s ease-in-out;
	}
	.cardHover:hover {
		
		box-shadow:
			0 1px 8px -2px rgba(0, 0, 0, 0.16),
			0 3px 24px 0 rgba(0, 0, 0, 0.12),
			0 5px 48px 4px rgba(0, 0, 0, 0.09)
		!important;
		
		transform: translate3d(0px, -2px, 0px);
	}
	
}